var favicon_images = [
    "./favicon/f1.png",
    "./favicon/f2.png",
    "./favicon/f3.png",
    "./favicon/f4.png",
    "./favicon/f5.png",
    "./favicon/f6.png",
    "./favicon/f7.png",
    "./favicon/f8.png",
    "./favicon/f9.png",
    "./favicon/f10.png",
    "./favicon/f11.png",
    "./favicon/f12.png",
    "./favicon/f13.png",
    "./favicon/f14.png",
    "./favicon/f15.png",
    "./favicon/f16.png",
    "./favicon/f17.png",
    "./favicon/f18.png",
  ],
  image_counter = 0; // To keep track of the current image

setInterval(function () {
  // remove current favicon
  if (document.querySelector("link[rel='icon']") !== null)
    document.querySelector("link[rel='icon']").remove();
  if (document.querySelector("link[rel='shortcut icon']") !== null)
    document.querySelector("link[rel='shortcut icon']").remove();

  // add new favicon image
  document
    .querySelector("head")
    .insertAdjacentHTML(
      "beforeend",
      '<link rel="icon" href="' +
        favicon_images[image_counter] +
        '" type="image/png">'
    );

  // If last image then goto first image
  // Else go to next image
  if (image_counter === favicon_images.length - 1) image_counter = 0;
  else image_counter++;
}, 200);
